<template>
  <!-- <div class="ma-4"> -->
  <v-container>
    <v-row>
      <!-- routingSteps Column -->
      <v-col cols="12" sm="4">
        <v-card class="grey lighten-5">
          <v-card-title class="grey lighten-2">
            {{ board.columns[0].name }}
            <v-spacer />
            <v-btn icon @click="newRoutingStep()">
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="colHeight">
            <v-row
              class="mt-1"
              v-for="task in board.columns[0].tasks"
              :key="task.idTask"
            >
              <v-col>
                <!-- <v-btn
                  @click="selectRoutingStep(task)"
                  width="100%"
                  max-width="200px"
                  height="100%"
                  class="pr-0 pl-0"
                  :style="{
                    borderRadius: '20px',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                  }"
                  text
                > -->
                <v-card
                  @click="selectRoutingStep(task)"
                  outlined
                  width="100%"
                  class="btn"
                  :style="{
                    border: '10px solid',
                    borderRadius: '20px',
                    borderColor: task.color,
                  }"
                >
                  <v-card-text v-if="task" class="text-center">
                    {{ task.processName }}
                  </v-card-text>
                </v-card>
                <!-- </v-btn> -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Timeline Column -->
      <v-col cols="12" sm="8" style="flex: 1">
        <v-card class="grey lighten-5">
          <v-card-title class="grey lighten-2">
            {{
              routingStepTemplate.cellName
                ? routingStepTemplate.cellName
                : "Nieuwe stap"
            }}
            <v-spacer />
            <!-- <v-btn icon>
              <v-icon dark> mdi-pencil </v-icon>
            </v-btn> -->
            <!-- <v-btn icon :loading="saving">
              <v-icon dark> mdi-content-save </v-icon>
            </v-btn> -->
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid" ref="form">
              <v-text-field
                label="Cell Naam"
                v-model="routingStepTemplate.cellName"
                :rules="[rules.required]"
                @change="busyEditting = true"
              ></v-text-field>
              <v-text-field
                label="Cell Code"
                v-model="routingStepTemplate.cellCode"
                validate-on-blur
                :rules="[rules.checkCellCodeValid, rules.required]"
                @change="busyEditting = true"
                @blur="checkCellAndOperationCode(routingStepTemplate)"
              ></v-text-field>
              <v-text-field
                label="Operation Naam"
                v-model="routingStepTemplate.operationName"
                validate-on-blur
                :rules="[rules.required]"
                @change="busyEditting = true"
              ></v-text-field>
              <v-text-field
                label="Operation Code"
                v-model="routingStepTemplate.operationCode"
                validate-on-blur
                :rules="[rules.checkOperationCodeValid, rules.required]"
                @change="busyEditting = true"
                @blur="checkCellAndOperationCode(routingStepTemplate)"
              ></v-text-field>
              <v-text-field
                label="Process Naam"
                v-model="routingStepTemplate.processName"
                validate-on-blur
                :rules="[rules.required]"
                @change="busyEditting = true"
              ></v-text-field>
              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-card-title>Resources</v-card-title>
                    <v-card-text>
                      <v-card
                        v-for="resource in routingStepTemplate.resources"
                        :key="resource.id + resource.tempResourceId"
                        class="ma-1"
                      >
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="8">
                              <v-text-field
                                dense
                                label="Naam"
                                v-model="resource.name"
                                validate-on-blur
                                :rules="[rules.required]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-checkbox
                                dense
                                label="In Gebruik"
                                v-model="resource.isActive"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                dense
                                label="Code"
                                v-model="resource.resourceCode"
                                validate-on-blur
                                :rules="[
                                  rules.checkResourceCodeValid(resource),
                                  rules.required,
                                ]"
                                @blur="checkResourceCode(resource)"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-card class="ma-1 grey lighten-5">
                        <v-card-text>
                          <v-row class="ma-1">
                            <v-col cols="12" sm="8">
                              <v-input
                                append-icon="mdi-close"
                                prepend-icon="mdi-plus"
                                @click:append="resourceToAdd.name = ''"
                                @click:prepend="addResource"
                              >
                                <v-text-field
                                  dense
                                  label="Naam"
                                  v-model="resourceToAdd.name"
                                  @change="busyEditting = true"
                                  @keydown.enter="addResource"
                                ></v-text-field>
                              </v-input>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-checkbox
                                dense
                                label="In Gebruik"
                                v-model="resourceToAdd.isActive"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                label="Code"
                                v-model="resourceToAdd.resourceCode"
                                validate-on-blur
                                :rules="[
                                  rules.checkResourceCodeValid(resourceToAdd),
                                ]"
                                dense
                                @change="busyEditting = true"
                                @blur="checkResourceCode(resourceToAdd)"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <br />
              <v-text-field
                @change="busyEditting = true"
                label="Tact Tijd (min)"
                :rules="[rules.numbers]"
                v-model="routingStepTemplate.tactTime"
              ></v-text-field>
              <v-text-field
                @change="busyEditting = true"
                label="Droogtijd (min)"
                :rules="[rules.numbers]"
                v-model="routingStepTemplate.coolingTime"
                hint="24u = 1440 min, 48u = 2880 min, 72u = 4320 min"
              ></v-text-field>
              <v-text-field
                @change="busyEditting = true"
                label="Sorteer Sleutel"
                :rules="[rules.required, rules.numbers]"
                v-model="routingStepTemplate.sortKey"
              ></v-text-field>
              Kleur:
              <v-dialog v-model="colorPicker" max-width="375">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="busyEditting = true"
                    :color="routingStepTemplate.color"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5">
                    {{ routingStepTemplate.color }}
                  </v-card-title>
                  <v-card-text>
                    <v-color-picker
                      class="ma-2"
                      dot-size="25"
                      mode="hexa"
                      canvas-height="200"
                      label="Color"
                      :rules="[rules.required]"
                      v-model="routingStepTemplate.color"
                    ></v-color-picker>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="colorPicker = false"
                    >
                      Sluit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text @click="saveStep" :loading="saving">
                  Opslaan
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- snackbar on save -->
    <v-snackbar
      v-model="saveSnackbar"
      :multi-line="true"
      :color="snackbarSuccess ? 'light-green' : 'red'"
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="saveSnackbar = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- Dialog to be sure to change RoutingStep -->
    <v-dialog v-model="changeRoutingStepDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title> U hebt niet opgeslaan </v-card-title>
        <v-card-subtitle>
          Bent u zeker dat u een andere cell wilt editeren?
        </v-card-subtitle>
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="changeRoutingStepDialog = false">
            Nee
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="changeRoutingStep(nextRoutingStep)"
          >
            Ja
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <!-- </div> -->
</template>

<script>
import { mapState } from "vuex";
import services from "@/services/services.js";
import { uuid } from "@/utils";

export default {
  props: {},
  components: {},
  created() {
    this.updateRoutingStepTemplates();
  },
  data() {
    return {
      cellAndOperationCodeValid: true,
      cellCodeValid: true,
      operationCodeValid: true,
      resourceCodeValid: [],
      changeRoutingStepDialog: false,
      busyEditting: false,
      nextRoutingStep: {},
      colorPicker: false,
      saving: false,
      edittingTask: false,
      resourceToAdd: {
        id: "00000000-0000-0000-0000-000000000000",
        name: "",
        resourceCode: "",
        isActive: true,
        tempResourceId: uuid(),
      },
      routingStepTemplate: {
        cellName: "",
        cellCode: "",
        operationName: "",
        operationCode: "",
        processName: "",
        tactTime: 60,
        coolingTime: 0,
        color: "#c8c8c8",
        image: null,
        sortKey: 20,
        routingStepTemplateId: "00000000-0000-0000-0000-000000000000",
        routingTemplateXRoutingStepTemplates: [],
        resources: [],
        routingSteps: [],
        id: "00000000-0000-0000-0000-000000000000",
      },
      valid: true,
      rules: {
        required: (value) => !!value || "Verplicht.",
        checkCellCodeValid: () =>
          this.cellAndOperationCodeValid || "Code combinatie bestaat reeds.",
        checkOperationCodeValid: () =>
          this.cellAndOperationCodeValid || "Code combinatie bestaat reeds.",
        checkResourceCodeValid: (val) =>
          this.validationHelperResourceCode(val) || "Code bestaat reeds.",
        numbers: (value) => {
          const pattern = /^([0-9]*)$/;
          return pattern.test(value) || "Enkel nummers.";
        },
      },
      saveSnackbar: false,
      snackbarSuccess: true,
      snackbarText: "",
    };
  },
  computed: {
    ...mapState(["board"]),
  },
  methods: {
    checkCellAndOperationCode(routingStepTemplate) {
      this.busyEditting = true;
      if (
        routingStepTemplate.cellCode != null &&
        routingStepTemplate.cellCode != "" &&
        routingStepTemplate.operationCode != null
      ) {
        services
          .validateCellAndOperationCode(
            routingStepTemplate.cellCode,
            routingStepTemplate.operationCode,
            this.routingStepTemplate.id
          )
          .then((response) => {
            this.cellAndOperationCodeValid = response.data;
            this.$refs.form.validate();
          });
      }
    },
    checkCellCode(routingStepTemplate) {
      this.busyEditting = true;
      if (
        routingStepTemplate.cellCode != null &&
        routingStepTemplate.cellCode != ""
      ) {
        services
          .validateCellCode(
            routingStepTemplate.cellCode,
            this.routingStepTemplate.id
          )
          .then((response) => {
            this.cellCodeValid = response.data;
            this.$refs.form.validate();
          });
      }
    },
    checkOperationCode(routingStepTemplate) {
      this.busyEditting = true;
      if (routingStepTemplate.operationCode != null) {
        services
          .validateOperationCode(
            routingStepTemplate.operationCode,
            this.routingStepTemplate.id
          )
          .then((response) => {
            this.operationCodeValid = response.data;
            this.$refs.form.validate();
          });
      }
    },
    checkResourceCode(resource) {
      this.busyEditting = true;
      if (resource.resourceCode != null && resource.resourceCode != "") {
        //check if resources here are no match (except this one)
        // console.log(resource);
        this.findOrUpdateResourceValidation(resource, true);
        // console.log(this.routingStepTemplate.resources);
        let foundDuplicateInThisStep = false;
        this.routingStepTemplate.resources.forEach((rstr) => {
          if (
            rstr.resourceCode.toUpperCase() ==
              resource.resourceCode.toUpperCase() &&
            (rstr.id != resource.id ||
              rstr.tempResourceId != resource.tempResourceId)
          ) {
            foundDuplicateInThisStep = true;
            this.findOrUpdateResourceValidation(resource, false);
          }
        });
        // console.log(foundDuplicateInThisStep);
        // if no match, check if resources in db are a match
        if (!foundDuplicateInThisStep) {
          services
            .validateResourceCode(resource.resourceCode, resource.id)
            .then((response) => {
              // console.log(response);
              this.findOrUpdateResourceValidation(resource, response.data);
            });
        }
      }
    },
    findOrUpdateResourceValidation(resource, valid) {
      let index = this.resourceCodeValid.findIndex(
        (x) => x.resource == resource
      );
      if (index < 0) {
        this.resourceCodeValid.push({
          resource: resource,
          valid: valid,
        });
      } else {
        this.resourceCodeValid[index].valid = valid;
      }
      this.$refs.form.validate();
    },
    validationHelperResourceCode(val) {
      let index = this.resourceCodeValid.findIndex((x) => x.resource == val);
      if (index < 0) {
        return true;
      }
      return this.resourceCodeValid[index].valid;
    },
    newRoutingStep() {
      const routingStepTemplate = {
        cellName: "",
        cellCode: "",
        operationName: "",
        operationCode: "",
        processName: "",
        tactTime: 60,
        coolingTime: 0,
        color: "#c8c8c8",
        image: null,
        sortKey: 20,
        resourceToAdd: {
          id: "00000000-0000-0000-0000-000000000000",
          name: "",
          resourceCode: "",
          isActive: true,
        },
        routingStepTemplateId: "00000000-0000-0000-0000-000000000000",
        routingTemplateXRoutingStepTemplates: [],
        resources: [],
        routingSteps: [],
        id: "00000000-0000-0000-0000-000000000000",
      };
      this.selectRoutingStep(routingStepTemplate);
    },
    addResource() {
      if (
        this.resourceToAdd.name != "" &&
        this.resourceToAdd.resourceCode != ""
      ) {
        let index = this.resourceCodeValid.findIndex(
          (x) => x.resource == this.resourceToAdd
        );
        if (index < 0) {
          this.checkResourceCode(this.resourceToAdd);
        }

        if (this.validationHelperResourceCode(this.resourceToAdd)) {
          this.busyEditting = true;
          // this.resourceToAdd.id = "00000000-0000-0000-0000-000000000000";
          // this.resourceToAdd.tempResourceId = ;
          this.routingStepTemplate.resources.push(
            JSON.parse(JSON.stringify(this.resourceToAdd))
          );
          this.resourceToAdd.name = "";
          this.resourceToAdd.tempResourceId = uuid();
          this.resourceToAdd.resourceCode = "";
        }
      }
    },
    selectRoutingStep(routingStep) {
      this.nextRoutingStep = routingStep;
      if (!this.busyEditting) {
        this.resourceToAdd = {
          id: "00000000-0000-0000-0000-000000000000",
          name: "",
          resourceCode: "",
          isActive: true,
        };
        this.routingStepTemplate = JSON.parse(JSON.stringify(routingStep));
      } else {
        this.changeRoutingStepDialog = true;
      }
    },
    changeRoutingStep(routingStep) {
      this.changeRoutingStepDialog = false;
      this.busyEditting = false;
      this.selectRoutingStep(routingStep);
    },
    saveStep() {
      this.saving = true;
      // this.routingStepTemplate.resources.forEach((resource) => {
      //   this.checkResourceCode(resource);
      // });

      if (this.valid) {
        this.sendRoutingStepTemplateToApi(this.routingStepTemplate);
      } else {
        this.$refs.form.validate();
        this.snackbarSuccess = false;
        this.snackbarText =
          "Gelieve de foutmeldingen eerst op te lossen alvorens op te slaan.";
        this.saveSnackbar = true;
        this.saving = false;
      }
    },
    sendRoutingStepTemplateToApi(routingStepTemplate) {
      // sending job to api
      services
        .saveRoutingStepTemplate(routingStepTemplate)
        .then((response) => {
          this.busyEditting = false;
          // console.log(response);
          if (response.data) {
            this.snackbarSuccess = true;
            this.snackbarText = "Opslaan was succesvol.";
            this.saveSnackbar = true;
            this.updateRoutingStepTemplates();
          }
        })
        .catch((error) => {
          this.snackbarSuccess = false;
          this.snackbarText =
            "Er was een probleem, gelieve opnieuw te proberen. \n Fout: " +
            error.response.data.detail;
          this.saveSnackbar = true;
          console.log(error.response.data.title);
          this.savingJob = false;
        })
        .finally(() => {
          this.saving = false;
        });
    },
    updateRoutingStepTemplates() {
      services
        .getRoutingStepTemplates()
        .then((response) => {
          this.$store.commit("SET_ROUTINGSTEPTEMPLATES", {
            response,
          });
        })
        .catch((error) => {
          this.$store.commit("SAVE_ERROR", { error });
          console.log(error.response.data.title);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
